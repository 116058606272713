import React from "react"

import SpotifySVG from "./spotify-svg"
import AppleMusicSVG from "./apple-music-svg"

import * as buttonStyles from "../styles/playOnPlatfromButtonStyles.module.scss"

const PlayOnPlatformButton = ({ platform }) => {
  let platformLink = ""
  if (platform === "spotify") {
    platformLink =
      "https://open.spotify.com/track/0xQ69QnTZraiE93OZrLIfq?si=d452723a6b1345c9"
  } else if (platform === "apple") {
    platformLink =
      "https://music.apple.com/cz/album/still-loving-your-eyes/1509519743?i=1509519744&l=cs"
  }

  return (
    <div className={buttonStyles.playOnPlatform}>
      <a href={platformLink} target="_blank" rel="noreferrer">
        <div className={buttonStyles.container}>
          <div className={buttonStyles.text}>
            <p>Listen on</p>
          </div>
          <div className={buttonStyles.icon}>
            {platform === "spotify" && <SpotifySVG />}
            {platform === "apple" && <AppleMusicSVG />}
          </div>
        </div>
      </a>
    </div>
  )
}

export default PlayOnPlatformButton
