import React from "react"

import BckgImage from "./bg-image"

import * as concertsStyles from "../styles/concerts.module.scss"

const Concerts = () => {
  return (
    <BckgImage section="concerts">
      <div className={concertsStyles.container}>
        <p>Concerts</p>
      </div>
    </BckgImage>
  )
}

export default Concerts
