import React from "react"

import Header from "./header"
import Footer from "./footer"
import Banner from "./banner"
import Art from "./art"
import Merch from "./merch"
import Concerts from "./concerts"

import "../styles/style.scss"
import "../styles/fonts.scss"

const Layout = props => {
  return (
    <div>
      <Header />
      <Banner />
      <Art />
      <Merch />
      <Concerts />
      {props.children}
      <Footer />
    </div>
  )
}

export default Layout
