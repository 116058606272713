import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import BckgImage from "./bg-image"

import * as merchStyles from "../styles/merch.module.scss"

const Merch = () => {
  return (
    <BckgImage section="merch">
      <div className={merchStyles.container}>
        <div className={merchStyles.clothes}>
          <div
            className={merchStyles.hoodie}
            //imgClassName={merchStyles.hoodieImg}
          >
            <StaticImage src="../images/hoodie_ph.png" alt="hoodie image" />
          </div>
          <div
            className={merchStyles.sleeveless}
            //imgClassName={merchStyles.sleevelessImg}
          >
            <StaticImage
              src="../images/sleeveless_ph.png"
              alt="sleeveless shirt image"
            />
          </div>
          <div
            className={merchStyles.tshirt}
            //imgClassName={merchStyles.tshirtImg}
          >
            <StaticImage src="../images/tshirt_ph.png" alt="t-shirt image" />
          </div>
        </div>
        <div className={merchStyles.shopMore}>shop for more</div>
      </div>
    </BckgImage>
  )
}

export default Merch
