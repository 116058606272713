import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

import * as bannerStyles from "../styles/banner.module.scss"

const GbiBridged = props => {
  const backgroundImages = useStaticQuery(graphql`
    query {
      # backgroundImages: file(
      #   relativePath: {
      #     eq: "images/background-images/bannerbg-paul-siewert-unsplash.jpg"
      #   }
      # ) {
      #   childImageSharp {
      #     gatsbyImageData(
      #       width: 2000
      #       quality: 50
      #       webpOptions: { quality: 70 }
      #     )
      #   }
      # }
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "images/background-images" }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 2000
                quality: 50
                webpOptions: { quality: 70 }
              )
            }
          }
        }
      }
    }
  `)

  // const imageBanner = getImage(backgroundImages)
  // const bgImageBanner = backgroundImages.allFile.edges.find(
  //   n => n.node.relativePath === "bannerbg-paul-siewert-unsplash.jpg"
  // ).node.childImageSharp.gatsbyImageData

  const bgImageBanner = getImage(
    backgroundImages.allFile.edges.find(
      n =>
        n.node.relativePath ===
        "images/background-images/bannerbg-paul-siewert-unsplash.jpg"
    ).node.childImageSharp
  )

  const bgImageArt = getImage(
    backgroundImages.allFile.edges.find(
      n =>
        n.node.relativePath ===
        "images/background-images/artbg-alexey-ruban-unsplash.jpg"
    ).node.childImageSharp
  )

  const bgImageConcerts = getImage(
    backgroundImages.allFile.edges.find(
      n =>
        n.node.relativePath ===
        "images/background-images/concertsbg-noita-digital-unsplash.jpg"
    ).node.childImageSharp
  )

  const bgImageMerch = getImage(
    backgroundImages.allFile.edges.find(
      n =>
        n.node.relativePath ===
        "images/background-images/merchbg-tina-dawson-unsplash.jpg"
    ).node.childImageSharp
  )

  let bgImage = ""

  switch (props.section) {
    case "banner":
      bgImage = bgImageBanner
      break
    case "merch":
      bgImage = bgImageMerch
      break
    case "art":
      bgImage = bgImageArt
      break
    case "concerts":
      bgImage = bgImageConcerts
      break
    default:
      bgImage = null
      break
  }

  return (
    <BgImage image={bgImage} className={bannerStyles.sectionBackground}>
      {props.children}
    </BgImage>
  )
}

export default GbiBridged
