import React from "react"

import * as bannerStyles from "../styles/banner.module.scss"

import YTvideo from "./yt-video"
import BckgImage from "./bg-image"

const Banner = () => {
  return (
    <BckgImage section="banner">
      <div className={bannerStyles.container}>
        <YTvideo
          videoSrcURL="https://www.youtube.com/embed/2sx33G9vHQ4?color=white&controls=0&autoplay=1"
          videoTitle="Rudy Stalk - Still Loving Your Eyes (Official Video)"
        />
      </div>
    </BckgImage>
  )
}

export default Banner
